<template>
  <div class="loading-screen">
    <div class="loading-content">
      <h1>אנא המתן לסיום התהליך</h1>
      <h1 class="loading-dots">{{ dots }}</h1>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  setup() {
    const dots = ref('');
    let intervalId = null;

    const animateDots = () => {
      const dotCount = (dots.value.length % 3) + 1;
      dots.value = '.'.repeat(dotCount);
    };

    onMounted(() => {
      intervalId = setInterval(animateDots, 500);
      // Simulating loading state for 3 seconds
     
    });

    onBeforeUnmount(() => {
      clearInterval(intervalId);
    });

    return {
      dots
    };
  }
};
</script>

<style>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333;
  font-size: 24px;
}

.loading-dots {
  animation: dots-animation 1s infinite;
}

@keyframes dots-animation {
  0% {
    content: '';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}
</style>
